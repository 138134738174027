import React from "react";
import SideScroll from "@organisms/SideScroll";
import loadable from "@loadable/component";

const GrowingImage = loadable(() => import("@organisms/GrowingImage"));

const GrowingImageSlide = ({ heading, sideScroll }) => {
  return (
    <GrowingImage
      heading={heading}
      image={sideScroll?.intro?.image}
      headingAlign="center"
    >
      <div className="sticky top-8 flex h-screen items-start md:items-center md:pl-14">
        <SideScroll {...sideScroll} />
      </div>
    </GrowingImage>
  );
};

GrowingImageSlide.defaultProps = {
  headingAlign: "left",
};

export default GrowingImageSlide;
